import Quill from 'quill';

; (function () {

  "use strict";

  var ArtForm = function () {
    const $editorElement = $('#editor');
    const $hiddenField = $('#hidden_editor');
    const $artForm = $('#art-form');

    var initializeQuillEditor = function () {
      if ($editorElement.length) {
        const Font = Quill.import('formats/font');
        Font.whitelist = ['sans-serif', 'serif', 'monospace', 'roboto', 'inconsolata', 'cursive'];
        Quill.register(Font, true);

        // Add a custom toolbar handler for the 'symbol' button
        const quill = new Quill('#editor', {
          theme: 'snow',
          modules: {
            toolbar: {
              container: [
                [{ font: Font.whitelist }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: [] }],
                ['link', 'code-block'],
                [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff'] }], // Custom color palette
                [{ background: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff'] }], // Custom background colors
                ['symbol'], // Placeholder for custom symbol button
              ],
              handlers: {
                // Custom handler for 'symbol'
                symbol: function () {
                  const symbols = ['©', '★', '♥', '♣', '♦', '✓', '✗', '☺', '☹']; // Define your symbol set
                  const symbol = prompt('Insert a symbol: ', symbols.join(' ')); // Prompt the user to pick a symbol
                  if (symbol) {
                    const range = this.quill.getSelection();
                    this.quill.insertText(range.index, symbol, 'user'); // Insert the selected symbol
                  }
                }
              }
            }
          }
        });


        // Store the Quill instance in a variable accessible to both event handlers
        window.quillInstance = quill;
      }
    }

    var initializeFormSubmit = function () {
      $artForm.on('submit', function (e) {
        e.preventDefault();
        // Get the HTML content of the editor
        const htmlContent = window.quillInstance.root.innerHTML;

        // Set the HTML content to the hidden field
        $hiddenField.val(htmlContent);
        this.submit();
      });
    }

    this.init = function () {
      initializeQuillEditor();
      initializeFormSubmit();
    };
  };

  var ArtIndex = function () {

    var initializeCollapseButtons = function () {
      $("#toggle-sexual-arts").on("click", function () {
        const userConfirmed = confirm(
          "The arts in this section are sexual in nature. Do you wish to proceed?"
        );

        if (userConfirmed) {
          $("#sexual-arts-section").slideDown(); // Reveal the section with a slide effect
          $(this).hide(); // Hide the button after confirmation
        }
      });

      $("#toggle-religion-arts").on("click", function () {
        const userConfirmed = confirm(
          "The arts in this section are religion in nature. Do you wish to proceed?"
        );

        if (userConfirmed) {
          $("#religion-arts-section").slideDown(); // Reveal the section with a slide effect
          $(this).hide(); // Hide the button after confirmation
        }
      });
    }

    this.init = function () {
      initializeCollapseButtons();
    };
  }


  if ($('body').data('controller') === 'star_arts' && ($('body').data('action') === 'new') || ($('body').data('action') === 'edit')) {
    var artForm = new ArtForm();
    artForm.init();
  } else if ($('body').data('controller') === 'star_arts' && ($('body').data('action') === 'index')) {
    var artIndex = new ArtIndex();
    artIndex.init();
  }
})();


